<script setup>

import DropDownItem from "~/components/Button/DropDownItem.vue";

const slots = useSlots();

const props = defineProps({
  label: String,
  items: Array,
  selectedIndex: {
    type: Number,
    default: 0
  }
})

const open = ref(false);
const refButton = ref();
const refPopup = ref();

const label = ref(props.items?.[props.selectedIndex].label || "");

const clickOutside = (event) => {
  if (open.value) open.value = false;
}

const toggleDropdown = () => {
  if (!open.value) {
    const {width} = refButton.value.getBoundingClientRect();
    refPopup.value.style.minWidth = width + "px";
  }
  open.value = !open.value;
}

</script>

<template>
  <div>
    <div ref="refButton" class="relative select-none w-fit" v-click-outside="clickOutside">
      <div @click="toggleDropdown"
           class="flex items-center gap-1 px-3">
        <div class="font-light text-sm leading-6 text-azure cursor-pointer">{{ label }}</div>
        <div>
          <IconChevronDown class="transition-all duration-500"
                           :class="{'rotate3D-x-180':open}"
          />
        </div>
      </div>
    </div>
    <div ref="refPopup" class="absolute z-50">
      <Accordion :expanded="open" class="relative shadow-box rounded bg-white">
        <div class="flex flex-col gap-2 px-3 py-2 whitespace-nowrap bg-white rounded">
          <ButtonDropDownItem v-for="item in props.items"
                        @click="label = item.label"
                        :href="item.href"
          >{{ item.label }}
          </ButtonDropDownItem>
        </div>
      </Accordion>
    </div>
  </div>
</template>
