<script setup>

</script>

<template>
  <AppHeader />
  <slot />
  <AppFooter />
  <Blend />
</template>
