<script setup>
const favorites = useCookie('vhs_fav')
const count = computed(() => favorites.value?.length || 0);
</script>

<template>
  <NuxtLink :to="{name: 'favorites'}" class="relative mr-2.5">
    <IconCompare />
    <div class="absolute flex justify-center items-center rounded-full bg-gray-700 w-3.5 h-3.5 -right-2.5 -bottom-1">
      <div class="text-white text-[9px]">{{ count }}</div>
    </div>
  </NuxtLink>
</template>
